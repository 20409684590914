import React from 'react'
import { RateConfig } from './RateConfig'
import { MinCommitConfig } from './MinCommitConfig'
import { IntervalConfig } from './IntervalConfig'
import { ChargeModelConfig } from './ChargeModelConfig'
import { AdjustmentConfig } from './AdjustmentConfig'
import { ProrationConfig } from './ProrationConfig'
import styled from 'styled-components'
import { ServiceConfig } from './ServiceConfig'

const ConfigContainer = styled.div`
  grid-area: config;
  overflow-y: auto;

  padding: 24px;
`

export function Config() {
  return (
    <ConfigContainer>
      <ServiceConfig />
      <IntervalConfig />
      <ChargeModelConfig />
      <MinCommitConfig />
      <RateConfig />
      <ProrationConfig />
      <AdjustmentConfig />
    </ConfigContainer>
  )
}
