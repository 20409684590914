import React from 'react'
import styled from 'styled-components'
import {
  FormGroup,
  Text,
  InputGroup,
  ControlGroup,
  HTMLSelect,
  Classes,
} from '@blueprintjs/core'
import { useGlobalState } from '..'

const ChargeModelSelect = styled(HTMLSelect)`
  display: block;
`

const SpecificDayInput = styled(InputGroup).attrs(() => ({
  type: 'number',
  step: 1,
}))``

export function ChargeModelConfig() {
  const { config, alterConfig } = useGlobalState()

  return (
    <FormGroup label="Charge model" labelFor="charge_model">
      {config.interval !== 'monthly' ? (
        <Text className={Classes.TEXT_MUTED}>Not applicable</Text>
      ) : (
        <ControlGroup fill>
          <ChargeModelSelect
            id="charge_model"
            value={config.chargeModel}
            onChange={(e) =>
              alterConfig((config) => {
                config.chargeModel = e.target.value as any
              })
            }
          >
            <option value="peak">Peak</option>
            <option value="average">Average</option>
            <option value="specific_day">Specific day</option>
          </ChargeModelSelect>
          {config.chargeModel === 'specific_day' && (
            <SpecificDayInput
              id="specific_day"
              value={String(config.chargeModelSpecificDay)}
              onChange={(e: any) =>
                alterConfig((config) => {
                  config.chargeModelSpecificDay = Number(e.target.value)
                })
              }
            />
          )}
        </ControlGroup>
      )}
    </FormGroup>
  )
}
