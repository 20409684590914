import { Step } from '../run'

const NAME = 'Minimum commit'

export const minCommitStep: Step = (data, config, output) => {
  if (!config.minCommitEnabled) {
    return output
  }

  Array.from(output.entries()).forEach(([date, dailyOutput], index) => {
    if (dailyOutput.subtotal_quantity === null) {
      return
    }

    switch (config.interval) {
      case 'daily':
        if (dailyOutput.subtotal_quantity < config.minCommit) {
          dailyOutput.steps.push({
            metric: 'quantity',
            name: NAME,
            in: [dailyOutput.subtotal_quantity, config.minCommit],
            op: 'MAX',
            out: config.minCommit,
          })
          dailyOutput.total_quantity = config.minCommit
          dailyOutput.min_commit_delta = {
            description: 'Min commit',
            delta_quantity:
              dailyOutput.total_quantity - dailyOutput.subtotal_quantity,
            delta_charge: 999,
          }
        }
        break

      case 'monthly':
        // Only process day in the month with usage
        if (dailyOutput.subtotal_quantity !== null) {
          if (dailyOutput.subtotal_quantity < config.minCommit) {
            dailyOutput.steps.push({
              metric: 'quantity',
              name: NAME,
              in: [dailyOutput.subtotal_quantity, config.minCommit],
              op: 'MAX',
              out: config.minCommit,
            })
            dailyOutput.total_quantity = config.minCommit
            dailyOutput.min_commit_delta = {
              description: 'Min commit',
              delta_quantity:
                dailyOutput.total_quantity - dailyOutput.subtotal_quantity,
              delta_charge: 999,
            }
          }
        }
        break
    }

    output.set(date, dailyOutput)
  })

  return output
}
