import React from 'react'
import styled from 'styled-components'
import { Button, FormGroup, InputGroup } from '@blueprintjs/core'
import { useGlobalState } from '..'
import { RateTier } from '../../types'

function generateRateTier(rateTier?: RateTier) {
  return {
    threshold: typeof rateTier !== 'undefined' ? rateTier.threshold + 100 : 0,
    rate: Math.round(Math.random() * 200) / 100,
  } as RateTier
}

const ThresholdInput = styled(InputGroup).attrs(() => ({
  type: 'number',
}))``

const RateInput = styled(InputGroup).attrs(() => ({
  type: 'number',
}))``

export function RateTiersConfig() {
  const { config, alterConfig } = useGlobalState()

  return (
    <FormGroup label="Tiers">
      <table style={{ width: '100%' }}>
        <colgroup>
          <col width="50%" />
          <col width="50%" />
          <col width="1" />
        </colgroup>
        <tbody>
          {config.rateTiers.map((rateTier, index) => (
            <tr>
              <td>
                <ThresholdInput
                  value={String(rateTier.threshold)}
                  onChange={(e: any) => {
                    alterConfig((config) => {
                      config.rateTiers[index].threshold = Number(e.target.value)
                    })
                  }}
                />
              </td>
              <td>
                <RateInput
                  value={String(rateTier.rate)}
                  leftIcon="dollar"
                  onChange={(e: any) => {
                    alterConfig((config) => {
                      config.rateTiers[index].rate = Number(e.target.value)
                    })
                  }}
                />
              </td>
              <td>
                <Button
                  icon="minus"
                  onClick={() => {
                    alterConfig((config) => {
                      config.rateTiers.splice(index, 1)
                    })
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={2}></td>
            <td>
              <Button
                icon="add"
                onClick={() => {
                  alterConfig((config) => {
                    config.rateTiers.push(
                      generateRateTier(
                        config.rateTiers[config.rateTiers.length - 1]
                      )
                    )
                  })
                }}
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </FormGroup>
  )
}
