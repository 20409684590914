import React from 'react'
import styled from 'styled-components'
import { Text } from '@blueprintjs/core'

export const DisclaimerContainer = styled(Text)`
  grid-area: disclaimer;
  padding: 8px 24px;
`

export function Disclaimer() {
  return (
    <DisclaimerContainer>
      Missing: rate revisions. Uncertainties: charge model uses quantity instead
      of p &amp; q (due to rate tiers)
    </DisclaimerContainer>
  )
}
