import React, { useState } from 'react'
import styled from 'styled-components'
import { DaysView } from './DaysView'
import { CsvView } from './CsvView'
import { Toolbar, Mode } from './Toolbar'

const Input = styled.div`
  grid-area: input;
  padding: 24px;

  flex-grow: 1;
  overflow-x: auto;
`

export function Data() {
  const [mode, setMode] = useState<Mode>('days')

  return (
    <>
      <Toolbar mode={mode} onModeChange={setMode} />
      <Input>{mode === 'days' ? <DaysView /> : <CsvView />}</Input>
    </>
  )
}
