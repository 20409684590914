import React from 'react'
import styled from 'styled-components'
import useDarkMode from 'use-dark-mode'
import { Config, Data, Disclaimer, Output } from '.'

const AppContainer = styled.div<{ darkMode: boolean }>`
  height: 100%;
  display: grid;

  background-color: ${({ darkMode }) => (darkMode ? '#394d5c' : '#eeeeee')};
  padding: 4px;

  grid-gap: 4px;
  grid-template-columns: 1fr 400px;
  grid-template-rows: 46px 1fr 2fr calc(1rem + 16px);
  grid-template-areas:
    'toolbar config'
    'input config'
    'output config'
    'disclaimer disclaimer';

  > div {
    border-radius: 12px;
    transition: background-color 0.1s;
    background-color: ${({ darkMode }) => (darkMode ? '#293742' : 'white')};
  }
`

export function Playground() {
  const darkMode = useDarkMode(undefined, {
    classNameDark: 'bp3-dark',
  })

  return (
    <AppContainer darkMode={darkMode.value}>
      <Data />
      <Config />
      <Output />
      <Disclaimer />
    </AppContainer>
  )
}
