import { Position, Tooltip } from '@blueprintjs/core'
import React from 'react'
import styled from 'styled-components'
import { ChargeData, Delta } from '../../types'
import { ExplainOutput } from './ExplainOutput'
import { Number } from './Number'

type DailyOutputProps = {
  header: string
  chargeData: ChargeData
}

export const Header = styled.h2`
  font-weight: normal;
  margin-top: 0;
`

export const Container = styled.div`
  min-width: 150px;

  &:not(:first-child) {
    padding-left: 1rem;
  }

  &:not(:last-child) {
    padding-right: 1rem;
  }
`

const Total = styled(Tooltip).attrs(() => ({
  position: Position.RIGHT,
  hoverOpenDelay: 0,
  transitionDuration: 0,
}))`
  > span {
    display: block;
    padding: 4px;
    font-size: 0.8rem;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: rgba(128, 128, 128, 0.4);
    }
  }
`

const TotalDetails = styled.div`
  p {
    margin-top: 0;
  }

  td {
    vertical-align: top;
    border-bottom: 1px solid silver;
  }
`

const NA = styled.div`
  text-align: right;
  width: 5rem;
  color: gray;
`

const DeltaRow = ({ data }: { data: Delta }) => (
  <tr>
    <td>{data.description}</td>
    <td>
      <Number>{data.delta_quantity}</Number>
    </td>
    <td>
      <Number prefix="$">{data.delta_charge}</Number>
    </td>
  </tr>
)

export function OutputColumn({ header, chargeData }: DailyOutputProps) {
  return (
    <Container>
      <Header>{header}</Header>
      {chargeData.subtotal_quantity === null ? (
        <NA>n/a</NA>
      ) : (
        <Number>{chargeData.subtotal_quantity}</Number>
      )}
      <Number prefix="$">
        {chargeData.subtotal_charge / chargeData.subtotal_quantity! || 0}
      </Number>
      <Number prefix="$">{chargeData.subtotal_charge}</Number>
      <div>&nbsp;</div>
      <Total>
        <>
          {chargeData.total_quantity === null ? (
            <NA>n/a</NA>
          ) : (
            <Number>{chargeData.total_quantity}</Number>
          )}
          <Number prefix="$">
            {chargeData.total_charge / chargeData.total_quantity! || 0}
          </Number>
          <Number prefix="$">{chargeData.total_charge}</Number>
        </>
        <TotalDetails>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Δ quantity</th>
                <th>Δ charge</th>
              </tr>
            </thead>
            <tbody>
              <DeltaRow data={chargeData.min_commit_delta} />
              {chargeData.adjustment_deltas.length > 0 &&
                chargeData.adjustment_deltas.map((delta) => (
                  <DeltaRow data={delta} />
                ))}
            </tbody>
          </table>
        </TotalDetails>
      </Total>
      {chargeData.steps.length > 0 ? (
        <ExplainOutput steps={chargeData.steps} />
      ) : null}
    </Container>
  )
}
