import { ChargeData, Config, Data, Output } from '../types'
import {
  adjustmentsStepFor,
  backfillDeltasStep,
  chargeModelStep,
  consolidateQuantityStep,
  effectiveRateStep,
  minCommitStep,
  prorationStep,
  rateStep,
  rateTiersStep,
} from './steps'

export type Step = (data: Data, config: Config, output: Output) => Output

export function generateOutput(): ChargeData {
  return {
    subtotal_quantity: null,
    total_quantity: null,
    subtotal_charge: 0,
    total_charge: 0,
    effective_rate: 0,
    min_commit_delta: {
      description: 'Min commit',
      delta_quantity: 0,
      delta_charge: 0,
    },
    adjustment_deltas: [],
    steps: [],
  }
}

export function run(data: Data, config: Config) {
  const steps = [
    consolidateQuantityStep,
    chargeModelStep,
    minCommitStep,
    adjustmentsStepFor('quantity'),
    effectiveRateStep,
    rateStep,
    rateTiersStep,
    prorationStep,
    adjustmentsStepFor('charge'),
    backfillDeltasStep,
  ]
  const output: Output = new Map()
  const serviceData: Data = new Map()

  // Filter service set in config
  Array.from(data.entries()).forEach(([date, data], index) => {
    serviceData.set(
      date,
      data.filter((usageRecord) => usageRecord.service === config.service)
    )
  })

  Array.from(serviceData.entries()).forEach(([date]) => {
    output.set(date, generateOutput())
  })

  return steps.reduce(
    (output, step) => step(serviceData, config, output),
    output
  )
}
