import { Step } from '../run'

const NAME = 'Rate'

export const rateStep: Step = (data, config, output) => {
  if (config.rateType === 'tiers') {
    return output
  }

  Array.from(output.entries()).forEach(([date, dailyOutput], index) => {
    if (
      dailyOutput.subtotal_quantity === null ||
      dailyOutput.total_quantity === null
    ) {
      return
    }

    const rate =
      config.rateType === 'manual' ? config.rate : dailyOutput.effective_rate

    dailyOutput.subtotal_charge = dailyOutput.subtotal_quantity * rate
    dailyOutput.total_charge = dailyOutput.total_quantity * rate
    dailyOutput.effective_rate = rate
    dailyOutput.steps.push({
      metric: 'charge',
      name: NAME,
      in: [dailyOutput.total_quantity, rate],
      op: '×',
      out: dailyOutput.total_charge,
    })
  })

  return output
}
