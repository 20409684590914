import { Step as ExplainStep } from '../../types'
import { Step } from '../run'

const NAME = 'Adjustments'

export const adjustmentsStepFor = (target: 'quantity' | 'charge') => {
  const adjustmentsStep: Step = (data, config, output) => {
    Array.from(output.entries()).forEach(([date, dailyOutput], index) => {
      if (dailyOutput.subtotal_quantity === null) {
        return
      }

      const children: ExplainStep[] = []
      const adjustmentAmounts: number[] = []
      const metric =
        target === 'charge'
          ? ('total_charge' as const)
          : ('total_quantity' as const)
      let runningTotal = dailyOutput[metric]!

      config.adjustments
        .map((adjustment, index) => ({ ...adjustment, id: index + 1 }))
        .filter((adjustment) => adjustment.target === target)
        .forEach((adjustment) => {
          const adjustmentAmount =
            adjustment.type === 'relative'
              ? runningTotal * adjustment.amount
              : adjustment.amount

          children.push({
            metric: target,
            name: `Adjustment ${adjustment.id}`,
            in:
              adjustment.type === 'relative'
                ? [runningTotal, adjustment.amount]
                : [adjustment.amount],
            op: adjustment.type === 'relative' ? '×' : '+',
            out: adjustmentAmount,
          })

          dailyOutput.adjustment_deltas.push({
            description: `Adjustment ${adjustment.id}`,
            delta_quantity: target === 'quantity' ? adjustmentAmount : 0,
            delta_charge: target === 'charge' ? adjustmentAmount : 999,
          })

          runningTotal = runningTotal + adjustmentAmount
          adjustmentAmounts.push(adjustmentAmount)
        })

      if (children.length > 0) {
        dailyOutput.steps.push({
          metric: target,
          name: NAME,
          in: [dailyOutput[metric]!, ...adjustmentAmounts],
          op: '+',
          out: runningTotal,
          children,
        })
        dailyOutput[metric] = runningTotal
      }
    })

    return output
  }

  return adjustmentsStep
}
