import React from 'react'
import styled from 'styled-components'
import { FormGroup, HTMLSelect } from '@blueprintjs/core'
import { useGlobalState } from '..'

const IntervalSelect = styled(HTMLSelect)`
  display: block;
`

export function IntervalConfig() {
  const { config, alterConfig } = useGlobalState()

  return (
    <FormGroup label="Interval" labelFor="interval">
      <IntervalSelect
        id="interval"
        value={config.interval}
        onChange={(e) =>
          alterConfig((config) => {
            config.interval = e.target.value as any
          })
        }
      >
        <option value="daily">Daily</option>
        <option value="monthly">Monthly</option>
      </IntervalSelect>
    </FormGroup>
  )
}
