import { Position, Tag, Tooltip } from '@blueprintjs/core'
import React, { Fragment } from 'react'
import styled from 'styled-components'
import { ChargeData } from '../../types'
import { Number } from './Number'

type StepListProps = {
  steps: ChargeData['steps']
}

const Container = styled.div`
  margin-top: 1rem;
`

const List = styled.ol`
  list-style: none;
  padding-left: 0;

  ol {
    margin-left: 10px;
  }
`

const Step = styled(Tooltip).attrs(() => ({
  wrapperTagName: 'li',
  position: Position.RIGHT,
  hoverOpenDelay: 0,
  transitionDuration: 0,
}))`
  > span {
    display: block;
    padding: 4px;
    font-size: 0.8rem;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: rgba(128, 128, 128, 0.4);
    }
  }
`

const StepSummary = styled.span``

const MetricLabel = styled(Tag)`
  color: #182026 !important;
  background-color: ${({ children }) =>
    children === 'quantity'
      ? '#b3e5fc'
      : children === 'rate'
      ? '#80cbc4'
      : '#ffb74d'} !important;
`

const Name = styled.span`
  padding-left: 4px;
`

const StepDetails = styled.div`
  p {
    margin-top: 0;
  }

  td {
    vertical-align: top;
    border-bottom: 1px solid silver;
  }
`

const Description = styled.p`
  font-style: italic;
`

function StepList({
  steps,
  type = '1',
}: StepListProps & { type?: 'a' | 'i' | '1' | 'A' | 'I' }) {
  return (
    <List type={type}>
      {steps.map((step, index) => (
        <Fragment key={index}>
          <Step>
            <StepSummary>
              <MetricLabel>{step.metric}</MetricLabel>
              <Name>{step.name}</Name>
            </StepSummary>
            <StepDetails>
              {step.description && (
                <Description>{step.description}</Description>
              )}
              <table>
                <tbody>
                  <tr>
                    <td>in</td>
                    <td>
                      {step.in.map((item, index) => (
                        <Number key={index}>{item}</Number>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td style={{ textAlign: 'right' }}>{step.op}</td>
                  </tr>
                  {step.op !== 'REJECT' && (
                    <tr>
                      <td>out</td>
                      <td>
                        <Number>{step.out!}</Number>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </StepDetails>
          </Step>
          {step.children && <StepList type="i" steps={step.children} />}
        </Fragment>
      ))}
    </List>
  )
}

export function ExplainOutput({ steps }: StepListProps) {
  return (
    <Container>
      <StepList steps={steps} />
    </Container>
  )
}
