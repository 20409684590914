import React, { createContext, useContext, useState, useCallback } from 'react'
import produce from 'immer'
import { Data, Config } from '../types'
import { generateData } from './Data/generateData'
import { generateConfig } from './Config/generateConfig'

export type State = {
  data: Data
  alterData: (produceFn: (data: Data) => Data | void) => void
  config: Config
  alterConfig: (produceFn: (config: Config) => Config | void) => void
}

export const StateContext = createContext<State>(undefined!)

export function useGlobalState() {
  return useContext(StateContext)
}

export function StateProvider({ children }: any) {
  const [data, setData] = useState<Data>(generateData())
  const [config, setConfig] = useState<Config>(generateConfig())

  const alterData = useCallback(
    (produceFn: (data: Data) => Data | void) => {
      setData(produce(data, produceFn))
    },
    [data]
  )

  const alterConfig = useCallback(
    (produceFn: (config: Config) => Config | void) => {
      setConfig(produce(config, produceFn))
    },
    [config]
  )

  const ctx = {
    data,
    alterData,
    config,
    alterConfig,
  }

  return <StateContext.Provider value={ctx}>{children}</StateContext.Provider>
}
