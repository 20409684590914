import { Step } from '../run'

const NAME = 'Effective rate'

export const effectiveRateStep: Step = (data, config, output) => {
  if (config.rateType !== 'automatic') {
    return output
  }

  Array.from(data.entries()).forEach(([date, dailyData], index) => {
    const dailyOutput = output.get(date)

    if (!dailyOutput) {
      throw new Error(`Date ${date} not defined in output`)
    }

    if (dailyData.length > 0) {
      dailyOutput.effective_rate = dailyData[0].rate
      dailyOutput.steps.push({
        metric: 'rate',
        name: NAME,
        in: dailyData.map((usageRecord) => usageRecord.rate),
        op: 'FIRST',
        out: dailyData[0].rate,
      })
    }
  })

  return output
}
