import React from 'react'
import styled from 'styled-components'
import { FormGroup, Button, InputGroup, HTMLSelect } from '@blueprintjs/core'
import { useGlobalState } from '..'
import { Adjustment } from '../../types'

function generateAdjustment() {
  return {
    type: 'relative',
    target: 'charge',
    amount: Math.round(Math.random() * 50) / 100,
  } as Adjustment
}

const TypeSelect = styled(HTMLSelect)`
  display: block;
`

const TargetSelect = styled(HTMLSelect)`
  display: block;
`

const AmountInput = styled(InputGroup).attrs(() => ({
  type: 'number',
}))``

export function AdjustmentConfig() {
  const { config, alterConfig } = useGlobalState()

  return (
    <FormGroup label="Adjustments">
      <table style={{ width: '100%' }}>
        <colgroup>
          <col width="33%" />
          <col width="33%" />
          <col width="33%" />
          <col width="1" />
        </colgroup>
        <tbody>
          {config.adjustments.map((adjustment, index) => (
            <tr key={index}>
              <td>
                <TypeSelect
                  value={adjustment.type}
                  onChange={(e) => {
                    alterConfig((config) => {
                      config.adjustments[index].type = e.target.value as any
                    })
                  }}
                >
                  <option value="relative">Relative</option>
                  <option value="absolute">Absolute</option>
                </TypeSelect>
              </td>
              <td>
                <TargetSelect
                  value={adjustment.target}
                  onChange={(e) => {
                    alterConfig((config) => {
                      config.adjustments[index].target = e.target.value as any
                    })
                  }}
                >
                  <option value="quantity">Quantity</option>
                  <option value="charge">Charge</option>
                </TargetSelect>
              </td>
              <td>
                <AmountInput
                  value={String(adjustment.amount)}
                  onChange={(e: any) =>
                    alterConfig((config) => {
                      config.adjustments[index].amount = Number(e.target.value)
                    })
                  }
                />
              </td>
              <td>
                <Button
                  icon="minus"
                  onClick={() => {
                    alterConfig((config) => {
                      config.adjustments.splice(index, 1)
                    })
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={3}></td>
            <td>
              <Button
                icon="plus"
                onClick={(e: any) => {
                  alterConfig((config) => {
                    config.adjustments.push(generateAdjustment())
                  })
                }}
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </FormGroup>
  )
}
