import { Step } from '../run'

const NAME = 'Proration'

export const prorationStep: Step = (data, config, output) => {
  if (
    !config.proration ||
    config.interval !== 'monthly' ||
    config.rateType === 'tiers'
  ) {
    return output
  }

  // Assume 31 days (jan)
  const factor = output.size / 31

  Array.from(output.entries()).forEach(([date, dailyOutput], index) => {
    if (dailyOutput.subtotal_quantity === null) {
      return
    }

    dailyOutput.steps.push({
      metric: 'charge',
      name: NAME,
      description: `Prorating for ${output.size} days of usage (${Math.round(
        factor * 100
      )}%)`,
      in: [dailyOutput.subtotal_charge, factor],
      op: '×',
      out: dailyOutput.subtotal_charge * factor,
    })
    dailyOutput.subtotal_charge = dailyOutput.subtotal_charge * factor
    dailyOutput.total_charge = dailyOutput.total_charge * factor
    dailyOutput.effective_rate = dailyOutput.effective_rate * factor
  })

  return output
}
