import React from 'react'
import styled from 'styled-components'
import NumberFormat, { NumberFormatProps } from 'react-number-format'

type NumberProps = NumberFormatProps & {
  children?: string | number
}

const StyledNumber = styled.div`
  text-align: right;
  width: 5rem;
`

export function Number(props: NumberProps) {
  return (
    <NumberFormat
      {...props}
      value={props.children}
      displayType="text"
      decimalScale={2}
      fixedDecimalScale
      renderText={(value) => <StyledNumber>{value}</StyledNumber>}
    />
  )
}
