import { Step } from '../run'

export const backfillDeltasStep: Step = (data, config, output) => {
  Array.from(data.entries()).forEach(([date, dailyData], index) => {
    const dailyOutput = output.get(date)

    if (!dailyOutput) {
      throw new Error(`Date ${date} not defined in output`)
    }

    // Min commit
    if (dailyOutput.min_commit_delta.delta_quantity > 0) {
      dailyOutput.min_commit_delta.delta_charge =
        dailyOutput.min_commit_delta.delta_quantity * dailyOutput.effective_rate
    }
  })

  return output
}
