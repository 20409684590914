import { Config } from '../../types'

export function generateConfig() {
  const initialConfig: Config = {
    service: 'Service 1',
    interval: 'daily',
    chargeModel: 'peak',
    chargeModelSpecificDay: 1,
    proration: false,
    rate: 0.25,
    rateType: 'manual',
    rateTiersType: 'standard',
    rateTiers: [
      { threshold: 0, rate: 0.25 },
      { threshold: 100, rate: 0.2 },
    ],
    minCommitEnabled: false,
    minCommit: 0,
    adjustments: [],
  }

  return initialConfig
}
