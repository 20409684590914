import { Step } from '../run'

const NAME = 'Charge model'

export const chargeModelStep: Step = (data, config, output) => {
  if (config.interval !== 'monthly') {
    return output
  }

  // Get daily quantities for MAX/AVG calculations later on
  let dailyQuantities: number[] = []
  Array.from(output.entries()).forEach(([date, dailyOutput], index) => {
    dailyQuantities[index] = dailyOutput.subtotal_quantity || 0
  })

  // Processing depends on charge model
  switch (config.chargeModel) {
    case 'peak':
      const highestQuantityIndex = dailyQuantities.indexOf(
        Math.max(...dailyQuantities)
      )

      Array.from(output.entries()).forEach(([date, dailyOutput], index) => {
        if (index === highestQuantityIndex) {
          dailyOutput.steps.push({
            metric: 'quantity',
            name: NAME,
            description: 'Highest usage',
            in: [dailyOutput.subtotal_quantity!],
            op: 'ACCEPT',
            out: dailyOutput.subtotal_quantity!,
          })
        } else {
          dailyOutput.steps.push({
            metric: 'quantity',
            name: NAME,
            description: 'Usage discarded',
            in: [0, dailyOutput.subtotal_quantity!],
            op: 'REJECT',
            out: null,
          })
          dailyOutput.subtotal_quantity = null
          dailyOutput.total_quantity = null
        }
      })
      break

    case 'average':
      // Assume 31 days (jan)
      const averageQuantity =
        dailyQuantities.reduce((sum, qty) => {
          return sum + qty
        }, 0) / 31

      Array.from(output.entries()).forEach(([date, dailyOutput], index) => {
        if (index === 0) {
          dailyOutput.steps.push({
            metric: 'quantity',
            name: NAME,
            description: 'Average usage',
            in: [
              ...dailyQuantities,
              ...Array(31 - dailyQuantities.length).fill(0),
            ],
            op: 'AVG',
            out: averageQuantity,
            children: [
              {
                metric: 'quantity',
                name: NAME,
                description: 'Usage moved to first day in month',
                in: [dailyOutput.subtotal_quantity!],
                op: 'MOVE',
                out: dailyOutput.subtotal_quantity!,
              },
            ],
          })
          dailyOutput.subtotal_quantity = averageQuantity
          dailyOutput.total_quantity = averageQuantity
        } else {
          dailyOutput.steps.push({
            metric: 'quantity',
            name: NAME,
            description: 'Usage discarded',
            in: [0, dailyOutput.subtotal_quantity!],
            op: 'REJECT',
            out: null,
            children: [
              {
                metric: 'quantity',
                name: NAME,
                description: 'Usage moved to first day in month',
                in: [dailyOutput.subtotal_quantity!],
                op: 'MOVE',
                out: dailyOutput.subtotal_quantity!,
              },
            ],
          })
          dailyOutput.subtotal_quantity = null
          dailyOutput.total_quantity = null
        }
      })
      break

    case 'specific_day':
      Array.from(output.entries()).forEach(([date, dailyOutput], index) => {
        if (index === config.chargeModelSpecificDay - 1) {
          dailyOutput.steps.push({
            metric: 'quantity',
            name: NAME,
            description: 'Specific day',
            in: [dailyOutput.subtotal_quantity!],
            op: 'ACCEPT',
            out: dailyOutput.subtotal_quantity!,
          })
        } else {
          dailyOutput.steps.push({
            metric: 'quantity',
            name: NAME,
            description: 'Usage discarded',
            in: [0, dailyOutput.subtotal_quantity!],
            op: 'REJECT',
            out: null,
          })
          dailyOutput.subtotal_quantity = null
          dailyOutput.total_quantity = null
        }
      })
      break
  }

  return output
}
