import React from 'react'
import styled from 'styled-components'
import { TextArea } from '@blueprintjs/core'
import Papa from 'papaparse'
import { formatISO, parseISO } from 'date-fns'
import { Data, UsageRecord } from '../../types'
import { useGlobalState } from '..'

type CsvRow = UsageRecord & {
  date: string
}

const CsvWrapper = styled.div`
  height: 100%;
`

const CsvInput = styled(TextArea)`
  box-sizing: border-box;
  width: 100%;
  height: 100% !important;
`

function dataToCsv(data: Data) {
  const dataArray = Array.from(data.entries()).reduce(
    (previous, [date, data]) => {
      const instanceArray = data.map((usageRecord) => {
        return {
          date: formatISO(date, { representation: 'date' }),
          ...usageRecord,
        }
      })
      return [...previous, ...instanceArray]
    },
    [] as CsvRow[]
  )
  return Papa.unparse(dataArray)
}

function csvToData(csv: string) {
  const result = Papa.parse<CsvRow>(csv, { header: true, dynamicTyping: true })
  const data: Data = new Map()

  const dayObject: {
    [key: string]: UsageRecord[]
  } = {}

  result.data.forEach(({ date, ...other }) => {
    if (!dayObject[date]) {
      dayObject[date] = []
    }

    dayObject[date].push(other)
  })

  Object.entries(dayObject).forEach(([date, records]) => {
    data.set(parseISO(date), records)
  })

  return data
}

export function CsvView() {
  const { data, alterData } = useGlobalState()
  const csv = dataToCsv(data)

  return (
    <CsvWrapper>
      <CsvInput
        value={csv}
        onChange={(e: any) => {
          alterData((data) => csvToData(e.target.value))
        }}
      />
    </CsvWrapper>
  )
}
