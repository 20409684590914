import React from 'react'
import { FormGroup, Text, Switch, Classes } from '@blueprintjs/core'
import { useGlobalState } from '..'

export function ProrationConfig() {
  const { config, alterConfig } = useGlobalState()

  return (
    <FormGroup label="Proration" labelFor="proration">
      {config.interval !== 'monthly' || config.rateType === 'tiers' ? (
        <Text className={Classes.TEXT_MUTED}>Not applicable</Text>
      ) : (
        <Switch
          id="proration"
          checked={config.proration}
          label="Enable"
          onChange={(e: any) =>
            alterConfig((config) => {
              config.proration = e.target.checked
            })
          }
        />
      )}
    </FormGroup>
  )
}
