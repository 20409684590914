import { UsageData } from '../../types'

export const MAX_NUMBER_OF_USAGE_RECORDS_PER_DAY = 4
export const MAX_NUMBER_OF_SERVICES_PER_DAY = 1
export const MAX_NUMBER_OF_INSTANCES_PER_DAY = 10
export const NUMBER_OF_DAYS = 4
export const MAX_QUANTITY = 10

function generateDate(key: number) {
  return new Date(2020, 0, key + 1)
}

const DEFAULT_QUANTITY_MAP = [10, 40, 40, 10]

const DEFAULT_RATE_MAP = [0.25, 0.25, 0.25, 0.2]

function generateUsage(key: number, random = false) {
  return Array.from({
    length: random
      ? Math.ceil(Math.random() * MAX_NUMBER_OF_USAGE_RECORDS_PER_DAY)
      : 1,
  }).map(() => {
    return {
      service: `Service ${
        random ? Math.ceil(Math.random() * MAX_NUMBER_OF_SERVICES_PER_DAY) : 1
      }`,
      instance: `Instance value ${
        random ? Math.ceil(Math.random() * MAX_NUMBER_OF_INSTANCES_PER_DAY) : 1
      }`,
      quantity: random
        ? Math.round(Math.random() * MAX_QUANTITY)
        : DEFAULT_QUANTITY_MAP[key],
      rate: random
        ? Math.round(Math.random() * 200) / 100
        : DEFAULT_RATE_MAP[key],
    }
  })
}

export function generateData(length: number = NUMBER_OF_DAYS, random = false) {
  const data = new Map<Date, UsageData>()
  Array.from({ length }).forEach((item, key) => {
    data.set(generateDate(key), generateUsage(key, random))
  })
  return data
}
