import React from 'react'
import styled from 'styled-components'
import { FormGroup, HTMLSelect } from '@blueprintjs/core'
import { useGlobalState } from '..'
import { Data } from '../../types'

const ServiceSelect = styled(HTMLSelect)`
  display: block;
`

function getServices(data: Data) {
  const services = Array.from(data.entries()).reduce(
    (previous, [date, data]) => {
      const dayServices = data.map((usageRecord) => {
        return usageRecord.service
      })
      return [...previous, ...dayServices]
    },
    [] as string[]
  )
  return [...new Set(services)].sort()
}

export function ServiceConfig() {
  const { data, config, alterConfig } = useGlobalState()
  const services = getServices(data)

  return (
    <FormGroup label="Service" labelFor="service">
      <ServiceSelect
        id="service"
        value={config.service}
        onChange={(e) =>
          alterConfig((config) => {
            config.service = e.target.value
          })
        }
      >
        {services.map((service, index) => (
          <option key={index} value={service}>
            {service}
          </option>
        ))}
      </ServiceSelect>
    </FormGroup>
  )
}
