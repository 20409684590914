import React from 'react'
import { generateOutput } from '../../chargeEngine/run'
import { Output } from '../../types'
import { OutputColumn } from './OutputColumn'

type AggregatedOutputProps = {
  output: Output
}

export function AggregatedOutput({ output }: AggregatedOutputProps) {
  const chargeData = Array.from(output.values()).reduce((previous, current) => {
    return {
      ...previous,
      subtotal_quantity:
        (previous.subtotal_quantity || 0) + (current.subtotal_quantity || 0),
      subtotal_charge: previous.subtotal_charge + current.subtotal_charge,
      total_quantity:
        (previous.total_quantity || 0) + (current.total_quantity || 0),
      total_charge: previous.total_charge + current.total_charge,
    }
  }, generateOutput())

  return <OutputColumn header="Aggregated" chargeData={chargeData} />
}
