import React from 'react'
import styled from 'styled-components'
import { UsageData } from '../../types'
import { useGlobalState } from '..'
import { DayUsageData } from './DayUsageData'

const DaysWrapper = styled.div`
  display: flex;
`

export function DaysView() {
  const { data, alterData } = useGlobalState()

  function changeUsageForDate(date: Date) {
    return function changeUsage(usage: UsageData) {
      alterData((data) => {
        data.set(date, usage)
      })
    }
  }

  return (
    <DaysWrapper>
      {Array.from(data.entries()).map(([date, usage], index) => (
        <DayUsageData
          key={index}
          date={date}
          usage={usage}
          onChange={changeUsageForDate(date)}
        />
      ))}
    </DaysWrapper>
  )
}
