import React from 'react'
import styled from 'styled-components'
import {
  FormGroup,
  ControlGroup,
  InputGroup,
  HTMLSelect,
} from '@blueprintjs/core'
import { useGlobalState } from '..'
import { RateTiersConfig } from './RateTiersConfig'

const RateTypeSelect = styled(HTMLSelect)`
  display: block;
`

const RateInput = styled(InputGroup).attrs(() => ({
  type: 'number',
}))``

const RateTierTypeSelect = styled(HTMLSelect)`
  display: block;
`

export function RateConfig() {
  const { config, alterConfig } = useGlobalState()

  return (
    <>
      <FormGroup label="Rate type" labelFor="rate_type">
        <ControlGroup fill>
          <RateTypeSelect
            id="rate_type"
            value={config.rateType}
            onChange={(e: any) =>
              alterConfig((config) => {
                config.rateType = e.target.value as any
              })
            }
          >
            <option value="manual">Manual</option>
            <option value="automatic">Automatic</option>
            <option value="tiers">Tiers</option>
          </RateTypeSelect>
          {config.rateType === 'tiers' && (
            <RateTierTypeSelect
              id="tiers_type"
              value={config.rateTiersType}
              onChange={(e: any) =>
                alterConfig((config) => {
                  config.rateTiersType = e.target.value as any
                })
              }
            >
              <option value="standard">Standard</option>
              <option value="inherited">Inherited</option>
            </RateTierTypeSelect>
          )}
        </ControlGroup>
      </FormGroup>
      {config.rateType === 'tiers' && <RateTiersConfig />}
      {config.rateType === 'manual' && (
        <FormGroup label="Manual rate" labelFor="manual_rate">
          <RateInput
            id="manual_rate"
            value={String(config.rate)}
            leftIcon="dollar"
            onChange={(e: any) =>
              alterConfig((config) => {
                config.rate = Number(e.target.value)
              })
            }
          />
        </FormGroup>
      )}
    </>
  )
}
