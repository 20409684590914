import React, { useState, useEffect, createContext, useContext } from 'react'
// @ts-ignore
import hashed from 'hashed'
// @ts-ignore
import { LZMA_WORKER as lzma } from 'lzma/src/lzma_worker-min'
import { useGlobalState } from './StateProvider'
import { Data, Config } from '../types'

export type History = {
  update: () => void
}

export const HistoryContext = createContext<History>(undefined!)

export function useHistory() {
  return useContext(HistoryContext)
}

export function HistoryProvider({ children }: any) {
  const { data, alterData, config, alterConfig } = useGlobalState()
  const [update, setUpdate] = useState<any>()

  const DEBUG = false

  // Register hashed
  useEffect(() => {
    if (update) {
      DEBUG && console.log('Skip registering again')
      return
    }

    function onNewState({ data, ...config }: Config & { data: Data }) {
      DEBUG && console.log('Received new state', { data, config })
      Object.keys(config).length > 0 && alterConfig(() => config)
      data.size > 0 && alterData(() => data)
    }

    DEBUG && console.log('Registering with defaults', { config, data })
    const updateFn = hashed.register(
      {
        ...config,
        data: {
          default: new Map(),
          serialize: function (data: Data) {
            const json = JSON.stringify(Object.fromEntries(data.entries()))
            const compressed = lzma.compress(json)
            const byteArray = new Uint8Array(compressed)
            const encoded = String.fromCharCode(...byteArray)
            const b64 = btoa(encoded)

            DEBUG &&
              console.log('Serialized', {
                json,
                compressed,
                byteArray,
                b64,
                ratio: b64.length / json.length,
              })

            return encodeURIComponent(b64)
          },
          deserialize: function (b64: string) {
            const decoded = atob(decodeURIComponent(b64))

            var buf = new ArrayBuffer(decoded.length * 1) // 1 byte for each char
            var byteArray = new Uint8Array(buf)
            for (var i = 0, strLen = decoded.length; i < strLen; i++) {
              byteArray[i] = decoded.charCodeAt(i)
            }
            const decompressed = lzma.decompress(byteArray)
            const json = JSON.parse(decompressed)

            const data = new Map()
            Object.keys(json).forEach((k) => {
              data.set(new Date(k), json[k])
            })

            DEBUG &&
              console.log('Deserialized', {
                b64,
                decoded,
                byteArray,
                decompressed,
                json,
              })

            return data
          },
        },
      },
      onNewState
    )
    setUpdate(() => updateFn)
  }, [DEBUG, alterConfig, alterData, config, data, update])

  const ctx = {
    update: () => {
      DEBUG && console.log('Updating URL', { config, data })
      update({ ...config, data })
    },
  }

  return (
    <HistoryContext.Provider value={ctx}>{children}</HistoryContext.Provider>
  )
}
