import React from 'react'
import ReactDOM from 'react-dom'
import { enableMapSet } from 'immer'
import './index.css'
import * as serviceWorker from './serviceWorker'
import { StateProvider, HistoryProvider, Playground } from './components'

enableMapSet()

ReactDOM.render(
  <React.StrictMode>
    <StateProvider>
      <HistoryProvider>
        <Playground />
      </HistoryProvider>
    </StateProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
