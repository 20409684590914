import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { run } from '../../chargeEngine'
import { Output as OutputType } from '../../types'
import { useGlobalState } from '../StateProvider'
import { AggregatedOutput } from './AggregatedOutput'
import { DailyOutput } from './DailyOutput'
import { Header } from './OutputColumn'

const OutputTable = styled.div`
  grid-area: output;
  padding: 24px;

  display: flex;
  overflow-x: auto;
`

export function Output() {
  const { data, config } = useGlobalState()
  const [output, setOutput] = useState<OutputType>(run(data, config))

  useEffect(() => {
    setOutput(run(data, config))
  }, [data, config])

  return (
    <OutputTable>
      <div>
        <Header>&nbsp;</Header>
        <div>
          <strong>Subtotal&nbsp;&nbsp;&nbsp;</strong>
        </div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <div>
          <strong>Total</strong>
        </div>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
      </div>
      <div>
        <Header>&nbsp;</Header>
        <div>Quantity</div>
        <div>Average rate</div>
        <div>Charge</div>
        <div>&nbsp;</div>
        <div>Quantity</div>
        <div>Average rate</div>
        <div>Charge</div>
      </div>
      <AggregatedOutput output={output} />
      <DailyOutput output={output} />
    </OutputTable>
  )
}
