import React from 'react'
import { formatISO } from 'date-fns'
import { Output } from '../../types'
import { OutputColumn } from './OutputColumn'

type DailyOutputProps = {
  output: Output
}

export function DailyOutput({ output }: DailyOutputProps) {
  return (
    <>
      {Array.from(output.entries()).map(([date, chargeData], index) => (
        <OutputColumn
          key={index}
          header={formatISO(date, { representation: 'date' })}
          chargeData={chargeData}
        />
      ))}
    </>
  )
}
