import { Step } from '../run'

const NAME = 'Consolidate'

export const consolidateQuantityStep: Step = (data, config, output) => {
  // We simply add up instance quantities for each day
  Array.from(data.entries()).forEach(([date, dailyData]) => {
    const dailyOutput = output.get(date)

    if (!dailyOutput) {
      throw new Error(`Date ${date} not defined in output`)
    }

    dailyOutput.subtotal_quantity = dailyData.reduce((previous, instance) => {
      return previous + instance.quantity
    }, 0)
    dailyOutput.total_quantity = dailyOutput.subtotal_quantity

    dailyOutput.steps.push({
      metric: 'quantity',
      name: NAME,
      in: dailyData.map((instance) => instance.quantity),
      op: '+',
      out: dailyOutput.subtotal_quantity,
    })
  })

  return output
}
