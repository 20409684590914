import React from 'react'
import styled from 'styled-components'
import { InputGroup } from '@blueprintjs/core'
import { formatISO } from 'date-fns'
import { UsageData, UsageRecord } from '../../types'
import { useGlobalState } from '../StateProvider'

type DayUsageDataProps = {
  date: Date
  usage: UsageData
  onChange: (usage: UsageData) => void
}

const Day = styled.div`
  white-space: nowrap;

  &:not(:first-child) {
    padding-left: 1rem;
  }

  &:not(:last-child) {
    padding-right: 1rem;
  }
`

const Date = styled.h2`
  font-weight: normal;
  margin-top: 0;
`

const Usage = styled.table`
  th {
    text-align: left;
  }
`

const QuantityInput = styled(InputGroup).attrs(() => ({
  type: 'number',
}))`
  width: 4em;
`

const RateInput = styled(InputGroup).attrs(() => ({
  type: 'number',
}))`
  width: 6em;
`

export function DayUsageData({ onChange, date, usage }: DayUsageDataProps) {
  const { config } = useGlobalState()

  function changeUsageAtIndex(index: number) {
    return function changeUsage(record: UsageRecord) {
      const newUsage = [...usage]
      newUsage[index] = record
      onChange(newUsage)
    }
  }
  return (
    <Day>
      <Date>{formatISO(date, { representation: 'date' })}</Date>
      <Usage>
        <thead>
          <tr>
            <th>Qty</th>
            {config.rateType === 'automatic' && <th>Rate</th>}
          </tr>
        </thead>
        <tbody>
          {usage.map((usageRecord, index) => (
            <tr key={index}>
              <td>
                <QuantityInput
                  value={String(usageRecord.quantity)}
                  onChange={(e: any) =>
                    changeUsageAtIndex(index)({
                      ...usageRecord,
                      quantity: Number(e.target.value),
                    })
                  }
                />
              </td>
              {config.rateType === 'automatic' && (
                <td>
                  <RateInput
                    value={String(usageRecord.rate)}
                    leftIcon="dollar"
                    onChange={(e: any) =>
                      changeUsageAtIndex(index)({
                        ...usageRecord,
                        rate: Number(e.target.value),
                      })
                    }
                  />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Usage>
    </Day>
  )
}
