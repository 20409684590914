import React from 'react'
import styled from 'styled-components'
import { FormGroup, Switch, ControlGroup, InputGroup } from '@blueprintjs/core'
import { useGlobalState } from '..'

const MinCommitInput = styled(InputGroup).attrs(() => ({
  type: 'number',
}))``

export function MinCommitConfig() {
  const { config, alterConfig } = useGlobalState()

  return (
    <FormGroup label="Minimum commit" labelFor="min_commit">
      <ControlGroup fill>
        <Switch
          id="min_commit"
          checked={config.minCommitEnabled}
          label="Enable"
          onChange={(e: any) =>
            alterConfig((config) => {
              config.minCommitEnabled = e.target.checked
            })
          }
        />
        {config.minCommitEnabled && (
          <MinCommitInput
            value={String(config.minCommit)}
            onChange={(e: any) =>
              alterConfig((config) => {
                config.minCommit = Number(e.target.value)
              })
            }
          />
        )}
      </ControlGroup>
    </FormGroup>
  )
}
