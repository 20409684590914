import { RateTier, Step as ExplainStep } from '../../types'
import { Step } from '../run'

const NAME = 'Rate tiers'

export const rateTiersStep: Step = (data, config, output) => {
  if (config.rateType !== 'tiers') {
    return output
  }

  switch (config.rateTiersType) {
    case 'standard':
      const tierState = [...config.rateTiers].map((rateTier, index, tiers) => {
        const nextTier = tiers[index + 1]
        const nextThreshold = nextTier ? nextTier.threshold : Infinity
        const remaining = nextThreshold - rateTier.threshold

        return {
          ...rateTier,
          remaining,
        }
      })
      Array.from(output.entries()).forEach(([date, dailyOutput], index) => {
        if (
          dailyOutput.subtotal_quantity === null ||
          dailyOutput.total_quantity === null
        ) {
          return
        }

        let remainingForDay = dailyOutput.total_quantity
        let tierCharges: number[] = []
        let rateSteps: ExplainStep[] = []

        tierState.forEach((tier, index) => {
          // If nothing remains, skip tier
          if (tier.remaining <= 0) {
            return
          }

          // Determine what quantity can be allotted for this tier
          const quantity = Math.min(tier.remaining, remainingForDay)

          if (quantity > 0) {
            const charge = quantity * tier.rate

            rateSteps.push({
              metric: 'charge',
              name: `Tier ${index + 1}`,
              in: [quantity, tier.rate],
              op: '×',
              out: charge,
            })

            tierCharges.push(charge)
            remainingForDay = remainingForDay - quantity
          }

          tier.remaining = tier.remaining - quantity
        })

        const tiersTotal = tierCharges.reduce((sum, charge) => sum + charge, 0)

        dailyOutput.steps.push({
          metric: 'charge',
          name: NAME,
          in: [dailyOutput.total_charge, ...tierCharges],
          op: '+',
          out: dailyOutput.total_charge + tiersTotal,
          children: rateSteps,
        })

        // Calculate average rate for this day
        dailyOutput.total_charge = dailyOutput.total_charge + tiersTotal
        dailyOutput.effective_rate =
          dailyOutput.total_charge / dailyOutput.total_quantity

        output.set(date, dailyOutput)
      })
      break

    case 'inherited':
      // First calculate total quantity for entire month
      const monthlyQuantity = Array.from(output.entries()).reduce(
        (previous, [date, dailyOutput], index) => {
          return previous + (dailyOutput.total_quantity || 0)
        },
        0
      )
      // Determine rate tier to use
      let effectiveTierIndex = -1
      const effectiveTier = config.rateTiers.reduce<RateTier | null>(
        (previous, rateTier, index) => {
          if (monthlyQuantity >= rateTier.threshold) {
            effectiveTierIndex = index
            return rateTier
          }
          return previous
        },
        null
      )

      if (!effectiveTier) {
        return output
      }

      Array.from(output.entries()).forEach(([date, dailyOutput], index) => {
        if (dailyOutput.total_quantity === null) {
          return
        }

        dailyOutput.total_charge =
          dailyOutput.total_quantity * effectiveTier.rate
        dailyOutput.effective_rate = effectiveTier.rate

        dailyOutput.steps.push({
          metric: 'charge',
          name: NAME,
          description: `tier ${effectiveTierIndex + 1}`,
          in: [dailyOutput.total_quantity, effectiveTier.rate],
          op: '×',
          out: dailyOutput.total_charge,
        })
      })
      break
  }

  return output
}
