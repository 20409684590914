import {
  Button,
  ControlGroup,
  FormGroup as BaseFormGroup,
  InputGroup,
  Switch,
  Text,
} from '@blueprintjs/core'
import React, { useState } from 'react'
import styled from 'styled-components'
import useDarkMode from 'use-dark-mode'
import { useGlobalState, useHistory } from '..'
import { generateData, NUMBER_OF_DAYS } from './generateData'

export type Mode = 'days' | 'csv'

type ToolbarProps = {
  mode: Mode
  onModeChange: (mode: Mode) => void
}

const FormGroup = styled(BaseFormGroup)``

const ToolbarContainer = styled.div`
  grid-area: toolbar;
  padding: 8px 24px;

  flex-grow: 0;
  display: flex;

  ${FormGroup}:not(:last-child) {
    padding-right: 8px;
    margin-right: 8px;
  }
`

const NumericInput = styled(InputGroup).attrs(() => ({
  type: 'number',
}))`
  width: 5em;
`

const Message = styled(Text)<{ visible: boolean }>`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 0.3s;
  line-height: 30px;
`

export function Toolbar({ mode, onModeChange }: ToolbarProps) {
  const { alterData } = useGlobalState()
  const { update } = useHistory()
  const [days, setDays] = useState(NUMBER_OF_DAYS)
  const [message, setMessage] = useState<string>()
  const [messageVisible, setMessageVisible] = useState(false)
  const darkMode = useDarkMode()

  function flashMessage(message: string) {
    setMessage(message)
    setMessageVisible(true)
    setTimeout(() => setMessageVisible(false), 4000)
  }

  return (
    <ToolbarContainer>
      <FormGroup inline label="Number of days" labelFor="days">
        <ControlGroup>
          <NumericInput
            id="days"
            value={String(days)}
            onChange={(e: any) => setDays(Number(e.target.value))}
          />
          <Button
            onClick={() => alterData(() => generateData(days, true))}
            icon="refresh"
          />
        </ControlGroup>
      </FormGroup>
      <FormGroup inline>
        <Switch
          checked={mode === 'csv'}
          label="Edit CSV"
          onChange={(e: any) => onModeChange(e.target.checked ? 'csv' : 'days')}
        />
      </FormGroup>
      <FormGroup inline>
        <Switch
          checked={darkMode.value}
          label="Dark mode"
          onChange={darkMode.toggle}
        />
      </FormGroup>
      <FormGroup inline>
        <Button
          icon="share"
          onClick={(e: any) => {
            update()
            setTimeout(() => {
              navigator.clipboard
                .writeText(window.location.href)
                .then(() => flashMessage('URL shared to clipboard'))
                .catch(() => flashMessage('Share current URL'))
            }, 0)
          }}
        >
          Share URL
        </Button>
      </FormGroup>
      <FormGroup inline>
        <Message visible={messageVisible}>{message}</Message>
      </FormGroup>
    </ToolbarContainer>
  )
}
